import styled from 'styled-components'

export const SliderContainer = styled.div<any>`
  border-bottom: 1px solid #1e2836;
  padding: 10px 0px;

  .title {
    font-size: 14px;
    margin-bottom: 10px;
    color: ${(props) => props.colors.accentDefault};
  }
  .input-range {
    padding: 0px 7px;
    position: relative;
    min-height: 40px;
    .slider {
      width: 100%;
      height: 6px;
      border-radius: 3px;
    }

    .slider-thumb {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;

      &:focus-visible {
        outline: none !important;
      }
    }

    .percent-options-container {
      color: ${(props) => props.colors.defaultText};
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      font-weight: 500;
    }
  }
`

export const MarkerWraper = styled.div<any>`
  position: absolute;
  left: ${(props) => props.leftOffset};
  top: 50%;
  transform: translate(-50%, -50%);

  .marker {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: border 0.2s ease-in-out;
    cursor: pointer;
    background: ${(props) =>
      props.isSelected ? props.colors.accentDefault : props.colors.defaultText};
    border: ${(props) =>
      props.isHovered ? '2px solid white' : '2px solid transparent'};
  }
  .marker-label {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    color: grey;
    font-size: 12px;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    max-width: ${(props) => (props.isLast ? '60px' : '40px')};
    transform: ${(props) =>
      props.isFirst
        ? 'translateX(-20%)'
        : props.isLast
        ? 'translateX(-80%)'
        : 'translateX(-50%)'};
  }
`
